<template>
  <div
    class="w-100 min-vh-100 position-fixed bg-primary d-flex flex-column"
    :style="{ zIndex: '2000', top: '0', left: '0' }"
  >
    <div class="flex-shrink-0"></div>
    <div class="my-auto text-white text-center">
      <h1>Page Not Found</h1>
      <b-btn
        variant="lightest text-primary"
        pill
        class="mt-5 fw-700 px-4"
        to="/"
        >메인페이지로 이동</b-btn
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>

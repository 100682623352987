<template>
  <b-navbar
    id="gnb"
    class="px-0 pb-2 pt-4 py-md-4 px-md-3 fixed-top justify-content-between align-items-start align-items-md-center"
    :key="$route.path"
  >
    <div class="pl-1 pl-md-0 d-flex align-items-center">
      <router-link
        to="/"
        class="mr-auto py-0 text-24 text-lg-32 ml-3 mb-0 cursor-pointer fw-700"
        tag="h1"
        :class="meta.headerClass ? meta.headerClass : 'text-primary'"
      >
        <!-- <img
          :src="require('@/assets/logo.png')"
          alt="로고 이미지, 메인페이지로 이동"
          class="w-100"
          aria-label="로고이미지, 메인페이지로 이동"
        /> -->
        jenna ko
      </router-link>
    </div>

    <b-navbar-toggle
      target="nav-collapse"
      class="pr-3 pr-md-0 border-0 cursor-pointer"
    >
      <template #default="{ expanded }">
        <div class="btn-hbg" :class="expanded ? 'active' : ''">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </template>
    </b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      class="mr-0 text-center text-md-left pr-3 pr-md-0"
      is-nav
    >
      <b-navbar-nav
        class="ml-auto mr-0 d-flex align-item-center text-16 text-lg-20"
      >
        <router-link
          class="router-link mr-3 mr-md-4 py-md-0 cursor-pointer"
          :class="meta.headerClass"
          to="/work"
        >
          <span>work</span>
        </router-link>
        <router-link
          class="router-link mr-3 mr-md-4 py-md-0 cursor-pointer"
          :class="meta.headerClass"
          to="/about"
        >
          <span>about</span>
        </router-link>
        <router-link
          class="router-link  py-md-0 cursor-pointer"
          :class="meta.headerClass"
          to="/contact"
        >
          <span>contact</span></router-link
        >
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="my-5 pt-4 pb-5 py-md-5">
    <aside
      class="pl-2 pl-md-4 position-relative w-75"
      :style="{ zIndex: '1031' }"
    >
      <b-form-radio-group
        v-model="category"
        buttons
        stacked
        button-variant="text text-left"
      >
        <b-form-radio :value="null">Show All</b-form-radio>
        <b-form-radio value="installation">Installation</b-form-radio>
        <b-form-radio value="scenic">Scenic Design</b-form-radio>
        <b-form-radio value="2d">2D Media</b-form-radio>
      </b-form-radio-group>
    </aside>

    <b-container class="mb-5 pb-5">
      <template v-if="works.length">
        <transition name="fade" mode="out-in">
          <b-row align-h="center">
            <b-col
              cols="10"
              md="6"
              class="py-3 py-md-5"
              v-for="(item, i) in works"
              :key="i"
            >
              <Item :item="item" />
            </b-col>
          </b-row>
        </transition>
      </template>

      <template v-if="works.length == 0">
        <b-row align-h="center" class="py-5 text-center">
          <b-col cols="12" md="5" class="mt-5 pt-5">
            <h6 class="text-24 text-lg-32 fw-700 text-primary">
              Work not founded
            </h6>
          </b-col>
        </b-row>
      </template>
    </b-container>
  </div>
</template>

<script>
import Item from "@/components/Item";
import allData from "@/lib/allData.js";
export default {
  name: "Home",
  metaInfo: {
    titleTemplate: `Work · %s`,
  },
  components: { Item },
  data() {
    return {
      category: null,
      allData: allData,
    };
  },
  computed: {
    works() {
      const arr = this.allData;

      if (this.category === null) {
        const result = arr.sort(function(a, b) {
          return b.order - a.order;
        });
        return result;
      } else {
        const result = arr
          .filter((r) => r.category === this.category)
          .sort(function(a, b) {
            return b.order - a.order;
          });
        return result;
      }
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<template>
  <div class="my-5 pb-5">
    <b-container
      class="p-5 text-white min-vh-100 d-flex flex-column justify-content-center"
    >
      <b-row align-h="center" class="mt-md-5">
        <b-col cols="12" md="6" lg="6">
          <div
            class="bg-img w-100 rounded-circle m-0 position-relative overflow-hidden shadow-lg"
            :style="{ paddingBottom: '100%' }"
          >
            <figure
              class="bg-img w-100 h-100 position-absolute position-center"
              :style="{ backgroundImage: `url(${profile.src})` }"
            ></figure>
          </div>
        </b-col>
      </b-row>
      <header class="pt-4 text-center">
        <h1 class="mb-4 text-28 text-lg-48 text-primary">Contact Jenna!</h1>
        <hr bold class="my-0 py-0" />
      </header>

      <article class="mt-5 px-2 px-md-3">
        <b-form @submit.prevent.stop="submit">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-input
                type="text"
                placeholder="Name"
                class="text-14 text-lg-16"
                v-model="input.name"
              ></b-form-input>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-input
                type="text"
                placeholder="Email Address"
                class="text-14 text-lg-16"
                v-model="input.contact"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-textarea
            placeholder="Message"
            no-resize
            class="text-14 text-lg-16"
            rows="4"
            v-model="input.msg"
          ></b-textarea>
          <div class="mt-3 text-right">
            <b-btn
              variant="primary"
              class="text-white rounded-circle shadow text-18 text-lg-18"
              type="submit"
              :style="{ width: '80px', height: '80px' }"
              >Submit</b-btn
            >
          </div>
        </b-form>
      </article>
    </b-container>
  </div>
</template>

<script>
import Item from "@/components/Item";
import emailjs from "emailjs-com";

export default {
  components: { Item },
  metaInfo: {
    titleTemplate: "Contact · %s",
  },
  data() {
    return {
      profile: {
        src: require("@/assets/contact.png"),
      },
      input: {
        name: "",
        contact: "",
        msg: "",
      },
    };
  },
  methods: {
    submit(e) {
      emailjs
        .send(
          "service_pzj2jff",
          "template_uv0i6mh",
          this.input,
          "user_WnXcJTSuicSgaxOOVLbpg"
        )
        .then(
          (res) => {
            this.$bvToast.toast("Thank you!", {
              title: "",
              headerClass: "d-none",
              toaster: "b-toaster-bottom-center",
              solid: true,
            });
            this.input = {
              name: "",
              contact: "",
              msg: "",
            };
          },
          (err) => {
            this.$bvToast.toast(`Send Fail. I'll fix this shortly.`, {
              title: "ERROR",
              variant: "error",
              toaster: "b-toaster-bottom-center",
              solid: true,
            });
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <footer
    class="w-100 p-4 p-md-2 bg-white d-flex align-items-center justify-content-between"
  >
    <!-- <b-btn
      variant="primary"
      class="rounded-circle text-24 shadow-lg text-white"
      :style="{
        width: '54px',
        height: '54px',
      }"
      @click="gotoUp"
      aria-label="Go to Top"
      >&uarr;</b-btn
    > -->
    <div class="">
      <!-- <b-btn variant="text" class="p-0" :style="{ width: '4rem' }">
        <img
          :src="require('@/assets/logo.png')"
          alt="로고 이미지"
          class="w-100"
        />
      </b-btn> -->
      <ul class="list-unstyled d-flex justify-content-end text-gray m-0 p-0">
        <li class="mb-0">© 2021 Jenna Ko</li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    gotoUp() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="px-0 bg-darkest">
    <b-container
      fluid
      class="px-0 w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center"
    >
      <b-row align-h="center" align-v="stretch" class="w-100">
        <b-col cols="12" md="10" lg="8" xl="5">
          <div
            class="bg-img w-100 ratio-100 rounded-circle"
            :style="{
              backgroundImage: `url(${thumbnails[idx]})`,
              maxHeight: '100vh',
              transition: '2s',
              boxShadow: '1rem 1rem 10rem rgba(26, 4, 27, 0.4)',
            }"
          ></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import allData from "@/lib/allData";
export default {
  name: "Home",
  data() {
    return {
      mainImage: require("@/assets/bg-main.png"),
      allData: allData,
      idx: 0,
    };
  },
  computed: {
    thumbnails() {
      const arr = this.allData;
      const temp = [];
      arr.forEach((el) => {
        temp.push(el.src);
      });
      return temp;
    },
  },
  mounted() {
    setInterval(() => {
      this.idx = Math.floor(Math.random() * Number(this.thumbnails.length));
    }, 6000);
  },
};
</script>

export default [
  {
    category: "installation",
    id: "dance-of-liberation",
    order: 16,
    title: "Dance of Liberation",
    src: require("@/assets/works/Installation/01dance/result_1.png"),
    year: "2021",
    infos:
      " Interference paint on plaster, epoxy clay, aluminum foil, projection",
    video: "https://www.youtube.com/embed/YTtXhX1L-KI",
    // desc:
    //   "This piece is about my own journey of self discovery and navigating what it means to be an artist. The boats voyage around the white cube, interacting with the space and other artists’ pieces in it. The projection is footage of the scenic beauties of Oberlin that we often pass by. I wanted to return to a childlike perspective of attention, sensitivity, affection, amusement, and curiosity about the environment. In order to do so, I used the camera as an extension of myself. The audience is welcome to walk around the space and become part of the piece by adding origami boats. As the piece grows, the artist grows through interaction with the inspiring minds here at Oberlin.",
    images: [
      require("@/assets/works/Installation/01dance/inprogress_0.png"),
      require("@/assets/works/Installation/01dance/inprogress_1.png"),
      require("@/assets/works/Installation/01dance/inprogress_6.png"),
      require("@/assets/works/Installation/01dance/inprogress_2.png"),
      require("@/assets/works/Installation/01dance/inprogress_3.png"),
      require("@/assets/works/Installation/01dance/inprogress_4.png"),
      require("@/assets/works/Installation/01dance/inprogress_5.png"),
      require("@/assets/works/Installation/01dance/inprogress_7.png"),
      require("@/assets/works/Installation/01dance/inprogress_8.png"),
      require("@/assets/works/Installation/01dance/result_0.png"),
      require("@/assets/works/Installation/01dance/result_1.png"),
    ],
  },
  {
    category: "installation",
    id: "voyage-of-a-young-artist",
    order: 15,
    title: "Voyage of a Young Artist",
    src: require("@/assets/works/Installation/02voyage/result_0.png"),
    year: "2020",
    infos: "Paint on cardboard, projection, vinyl",
    video: "https://www.youtube.com/embed/SPekoBegrbU",
    desc:
      "This piece is about my own journey of self discovery and navigating what it means to be an artist. The boats voyage around the white cube, interacting with the space and other artists’ pieces in it. The projection is footage of the scenic beauties of Oberlin that we often pass by. I wanted to return to a childlike perspective of attention, sensitivity, affection, amusement, and curiosity about the environment. In order to do so, I used the camera as an extension of myself. The audience is welcome to walk around the space and become part of the piece by adding origami boats. As the piece grows, the artist grows through interaction with the inspiring minds here at Oberlin.",
    images: [
      require("@/assets/works/Installation/02voyage/001.png"),
      require("@/assets/works/Installation/02voyage/inprogress_0.png"),
      require("@/assets/works/Installation/02voyage/inprogress_1.png"),
      require("@/assets/works/Installation/02voyage/inprogress_2.png"),
      require("@/assets/works/Installation/02voyage/002.png"),
      require("@/assets/works/Installation/02voyage/003.png"),
      require("@/assets/works/Installation/02voyage/result_0.png"),
      require("@/assets/works/Installation/02voyage/result_1.png"),
    ],
  },
  {
    category: "installation",
    id: "blue-tears",
    order: 14,
    title: "Blue Tears",
    src: require("@/assets/works/Installation/03blueTears/result_0.png"),
    year: "2021",
    infos:
      "Paint on papier-mâché, chicken wire, copper push pin, LED, fabric, hanji, recycling bag",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    images: [
      require("@/assets/works/Installation/03blueTears/inprogress_0.png"),
      require("@/assets/works/Installation/03blueTears/inprogress_1.png"),
      // require("@/assets/works/Installation/03blueTears/result_0.png"),
      require("@/assets/works/Installation/03blueTears/result_1.png"),
      require("@/assets/works/Installation/03blueTears/result_2.png"),
    ],
  },
  {
    category: "installation",
    id: "mini-you",
    order: 13,
    title: "Mini-You",
    src: require("@/assets/works/Installation/04miniYou/result_0.png"),
    year: "2020",
    infos: "Paint on wood, crêpe paper",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    images: [
      // require("@/assets/works/Installation/04miniYou/result_0.png"),
      require("@/assets/works/Installation/04miniYou/result_1.png"),
    ],
  },
  {
    category: "installation",
    id: "nebula",
    order: 8,
    title: "Nebula",
    src: require("@/assets/works/Installation/05nebula/result_0.png"),
    year: "2020",
    infos: "Paint on wood, borax crystal, Adobe Premiere",
    video: "https://www.youtube.com/embed/xGASCTKYdQg",
    // desc:
    //   "This piece is about my own journey of self discovery and navigating what it means to be an artist. The boats voyage around the white cube, interacting with the space and other artists’ pieces in it. The projection is footage of the scenic beauties of Oberlin that we often pass by. I wanted to return to a childlike perspective of attention, sensitivity, affection, amusement, and curiosity about the environment. In order to do so, I used the camera as an extension of myself. The audience is welcome to walk around the space and become part of the piece by adding origami boats. As the piece grows, the artist grows through interaction with the inspiring minds here at Oberlin.",
    images: [require("@/assets/works/Installation/05nebula/result_1.png")],
  },
  {
    category: "installation",
    id: "the-black-book",
    order: 7,
    title: "The Black Book",
    src: require("@/assets/works/Installation/06blackBook/result_0.png"),
    year: "2019",
    infos: "Paint on wood, Adobe Illustrator, Adobe After Effects",
    video: "https://www.youtube.com/embed/oXwXq8Ur1XY",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    images: [
      require("@/assets/works/Installation/06blackBook/result_0.png"),
      require("@/assets/works/Installation/06blackBook/001.png"),
    ],
  },
  {
    category: "installation",
    id: "inspiration",
    order: 6,
    title: "Inspiration",
    src: require("@/assets/works/Installation/07inspiration/result_0.png"),
    year: "2019",
    infos: "Arduino, LED, wood, plexiglass",
    video: "https://www.youtube.com/embed/2xc7kjjUzfk",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    images: [
      require("@/assets/works/Installation/07inspiration/inprogress_0.png"),
      require("@/assets/works/Installation/07inspiration/result_0.png"),
    ],
  },
  {
    category: "installation",
    id: "sangsul-yi",
    order: 0,
    title: "Sangsul Yi",
    src: require("@/assets/works/Installation/08sangsulYi/result_0.png"),
    year: "2018",
    infos: "Adobe Illustrator (with Yeojin Choi)",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    images: [
      require("@/assets/works/Installation/08sangsulYi/result_1.png"),
      require("@/assets/works/Installation/08sangsulYi/result_2.png"),
    ],
  },
  {
    category: "installation",
    id: "emergence",
    order: 24,
    title: "Emergence",
    src: require("@/assets/works/Installation/09emergence/result_0.jpg"),
    year: "2021",
    infos: "Chicken wire, found wood",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    images: [
      require("@/assets/works/Installation/09emergence/inprogress_0.jpg"),
    ],
  },
  {
    category: "2d",
    id: "starlight-in-the-lily-pond",
    order: 12,
    title: "Starlight in the Lily Pond",
    src: require("@/assets/works/2d/01_thumb.png"),
    year: "2020",
    infos: "Acrylic paint on wood, 48 x 36 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    images: [require("@/assets/works/2d/01.png")],
  },
  {
    category: "2d",
    id: "color-of-love",
    order: 11,
    title: "Color of Love",
    src: require("@/assets/works/2d/02_thumb.png"),
    year: "2020",
    infos: "Acrylic paint and oil pastel on wood, 48 x 48 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    images: [require("@/assets/works/2d/02.png")],
  },
  {
    category: "2d",
    id: "recognition",
    order: 9,
    title: "Recognition",
    src: require("@/assets/works/2d/03.png"),
    year: "2020",
    infos: "Colored pencil on paper, 9 x 12 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "2d",
    id: "hope",
    order: 10,
    title: "Hope",
    src: require("@/assets/works/2d/04.png"),
    year: "2020",
    infos: "Colored pencil and watercolor on paper, 12 x 9 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "2d",
    id: "flower-vase",
    order: 5,
    title: "Flower Vase",
    src: require("@/assets/works/2d/05.png"),
    year: "2018",
    infos: "Charcoal, ink, and oil bar on paper, 43.5 x 58 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "2d",
    id: "self-portrait",
    order: 4,
    title: "Self-Portrait",
    src: require("@/assets/works/2d/06.png"),
    year: "2018",
    infos: "Charcoal on paper, 24 x 16 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "2d",
    id: "self-portrait-2",
    order: 3,
    title: "Self-Portrait",
    src: require("@/assets/works/2d/07.png"),
    year: "2018",
    infos: "Charcoal on paper, 24 x 16 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "2d",
    id: "clarence-ward-art-library",
    order: 2,
    title: "Clarence Ward Art Library",
    src: require("@/assets/works/2d/08.png"),
    year: "2018",
    infos: "Pencil on paper, 24 x 16 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "2d",
    id: "peters-hall",
    order: 1,
    title: "Peters Hall",
    src: require("@/assets/works/2d/09.png"),
    year: "2018",
    infos: "Pencil on paper, 24 x 16 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "2d",
    id: "harmony",
    order: 17,
    title: "Harmony",
    src: require("@/assets/works/2d/harmony.jpg"),
    year: "2021",
    infos: "Oil on wood, 66 x 48 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "2d",
    id: "daydreaming",
    order: 18,
    title: "Daydreaming",
    src: require("@/assets/works/2d/daydreaming.jpg"),
    year: "2021",
    infos: "Acrylic paint on canvas, 16 x 20 inches",
    // desc:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    category: "scenic",
    id: "sunset-baby",
    order: 19,
    title: "Sunset Baby",
    src: require("@/assets/works/scenic/sunset/result_0.png"),
    year: "2021",
    infos: "Written by Dominique Morisseau",
    desc:
      "This is a quarter inch white model of the first project in my set design class. It represents a small apartment in New York. Also included in here are images of my sketches and ground plan.",
    images: [
      require("@/assets/works/scenic/sunset/inprogress_0.png"),
      require("@/assets/works/scenic/sunset/inprogress_1.png"),
      require("@/assets/works/scenic/sunset/inprogress_2.png"),
      require("@/assets/works/scenic/sunset/result_0.png"),
    ],
  },
  {
    category: "scenic",
    id: "silent-sky",
    order: 20,
    title: "Silent Sky",
    src: require("@/assets/works/scenic/silent/result_0.jpg"),
    year: "2021",
    infos: "Written by Lauren Gunderson",
    images: [
      require("@/assets/works/scenic/silent/inprogress_0.jpg"),
      require("@/assets/works/scenic/silent/inprogress_1.jpg"),
      require("@/assets/works/scenic/silent/inprogress_2.jpg"),
      // require("@/assets/works/scenic/silent/result_0.jpg"),
    ],
  },
  {
    category: "scenic",
    id: "angels-bone",
    order: 21,
    title: "Angel’s Bone",
    src: require("@/assets/works/scenic/angels/result_0.jpg"),
    year: "2021",
    infos: "Written by Du Yun",
    images: [
      require("@/assets/works/scenic/angels/inprogress_0.jpg"),
      require("@/assets/works/scenic/angels/inprogress_1.jpg"),
    ],
  },
  {
    category: "scenic",
    id: "or",
    order: 22,
    title: "Or,",
    src: require("@/assets/works/scenic/or/result_0.jpg"),
    year: "2021",
    infos: "Written by Liz Duffy Adams",
    images: [
      require("@/assets/works/scenic/or/inprogress_0.jpg"),
      require("@/assets/works/scenic/or/inprogress_1.jpg"),
      require("@/assets/works/scenic/or/inprogress_2.jpg"),
      require("@/assets/works/scenic/or/inprogress_3.jpg"),
      require("@/assets/works/scenic/or/inprogress_4.jpg"),
      require("@/assets/works/scenic/or/inprogress_5.jpg"),
    ],
  },
  {
    category: "scenic",
    id: "nest",
    order: 23,
    title: "Nest",
    src: require("@/assets/works/scenic/nest/result_0.jpg"),
    year: "2021",
    infos: "Paper, found wood, eggshell, scale figure",
    images: [
      require("@/assets/works/scenic/nest/inprogress_0.jpg"),
      require("@/assets/works/scenic/nest/inprogress_1.jpg"),
      require("@/assets/works/scenic/nest/inprogress_2.jpg"),
    ],
  },
];

<template>
  <div
    class="item-work bg-img w-100 rounded-circle m-0 position-relative overflow-hidden"
    :style="{ paddingBottom: '100%' }"
  >
    <div class="dim w-100 h-100 position-center" />
    <h3
      class="item-title text-24 text-white text-center position-center"
      :style="{ zIndex: '4', pointerEvents: 'none' }"
    >
      {{ item.title }}
    </h3>
    <figure
      class="bg-img w-100 h-100 position-absolute position-center"
      :style="
        item.src
          ? { backgroundImage: `url(${item.src})` }
          : { backgroundColor: '#1a041b' }
      "
      @click="$router.push('/work/' + item.id)"
    ></figure>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="py-5 py-5">
    <b-row align-h="center" class="mt-5">
      <b-col cols="12" md="12" lg="8" xl="7">
        <header class="pb-2 position-relative" :style="{ zIndex: '20' }">
          <b-btn
            variant="text text-primary"
            class="mb-2 p-0"
            @click="$router.go(-1)"
          >
            &larr; GO BACK
          </b-btn>
          <div class="d-flex align-items-start">
            <h2 class="text-48 text-lg-56 fw-700 text-primary">
              {{ currentWork.title }}
            </h2>
            <!-- <b-btn
          variant="text text-primary"
          class="mb-2 p-0"
          @click="$router.go(-1)"
        >
          &larr; GO BACK
        </b-btn> -->
          </div>

          <ul
            class="list-unstyled d-md-flex align-items-center m-0 p-0 text-14 text-lg-16 text-light"
          >
            <li class="mr-1">
              <span>
                {{ `${currentWork.year}${currentWork.infos ? "," : ""}` }}
              </span>
            </li>
            <li class="mr-1">{{ currentWork.infos }}</li>
          </ul>
        </header>
        <article class="mb-5 pb-5">
          <hr bold />
          <template v-if="currentWork.video">
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="currentWork.video"
              allowfullscreen
            ></b-embed>
          </template>
          <template v-else>
            <img
              :src="currentWork.src"
              :alt="currentWork.title"
              class="w-100"
            />
          </template>

          <hr bold />

          <section class="mt-3">
            <p class="text-14 text-lg-16 lh-200 text-gray">
              {{ currentWork.desc }}
            </p>
          </section>

          <section class="mt-5">
            <img
              :alt="currentWork.title + '이미지'"
              class="w-100 mb-2"
              v-for="(item, i) in currentWork.images"
              :src="item"
              :key="i"
            />
          </section>
        </article>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import allData from "@/lib/allData.js";
export default {
  metaInfo() {
    const currentWork = this.currentWork;
    return {
      titleTemplate: currentWork ? `${currentWork.title} · %s` : "%s",
    };
  },
  data() {
    return {
      allData: allData,
      idx: this.$route.path.split("/")[2],
    };
  },
  computed: {
    currentWork() {
      const temp = this.allData.find((r) => r.id == this.idx);
      if (temp) {
        return temp;
      } else {
        this.$router.push("/err");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div id="app">
    <main class="d-flex flex-column">
      <global-nav />
      <b-container
        fluid
        :class="$route.path === '/' ? 'px-0' : ''"
        :style="{ transition: '0.3s' }"
      >
        <transition name="fade" mode="out-in">
          <router-view class="layout" />
        </transition>
      </b-container>
      <global-footer />
    </main>
  </div>
</template>

<script>
import Nav from "@/components/Nav/index";
export default {
  components: {
    GlobalNav: Nav.GlobalNav,
    GlobalFooter: Nav.GlobalFooter,
  },
  metaInfo: {
    title: "Jenna Ko",
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<template>
  <div class="my-5 py-5">
    <b-row align-h="center" class="mt-md-5">
      <b-col cols="12" md="6" lg="4">
        <div
          class="bg-img w-100 rounded-circle m-0 position-relative overflow-hidden shadow-lg"
          :style="{ paddingBottom: '100%' }"
        >
          <figure
            class="bg-img w-100 h-100 position-absolute position-center"
            :style="{ backgroundImage: `url(${profile.src})` }"
          ></figure>
        </div>
        <!-- <div class="mt-n3 text-right">
          <span class="text-12 text-lg-14 text-light"
            >Photo by Charlie Chen
          </span>
        </div> -->

        <article class="mt-4">
          <header class="text-center">
            <h1 class="text-center text-primary fw-700 text-36 text-lg-48">
              jenna ko
            </h1>
            <!-- <span>b. Columbus, OH</span> -->
          </header>
          <section class="mt-3 mb-5">
            <p class="text-16 text-lg-18 lh-200 py-3">
              Jenna Ko (b. Columbus, OH) is a multidisciplinary artist whose
              work includes video sculpture, installation, digital art, and set
              design. She loves to capture and appreciate the vitality of nature
              through light and movement. Ko is based in Seoul, Korea. She holds
              a BA in Studio Art from Oberlin College and is pursuing an MFA in
              Theatre (Design emphasis) at the Ohio State University starting
              Fall 2021.
            </p>
          </section>
          <ul class="list-unstyled">
            <li v-for="(list, i) in allList" :key="i" class="mb-5">
              <h3
                class="text-24 text-lg-32 text-primary text-uppercase pb-2 border-bottom fw-700"
              >
                {{ list.heading }}
              </h3>
              <ul class="list-unstyled text-gray">
                <li class="mb-4" v-for="(item, l) in list.items" :key="l">
                  <h4 class="text-24 text-lg-20 fw-700 text-primary">
                    {{ item.period }}
                  </h4>
                  <div class="text-14 text-lg-16">
                    <strong class="text-primary">{{ item.strong }}</strong>
                    <span>{{ item.span }}</span>
                  </div>

                  <template v-if="item.desc">
                    <ul class="m-0 pl-3 pt-2">
                      <li
                        class="mb-3 text-14 text-lg-16"
                        v-for="(desc, k) in item.desc"
                        :key="k"
                      >
                        {{ desc }}
                      </li>
                    </ul>
                  </template>
                </li>
              </ul>
            </li>
          </ul>
        </article>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: "About · %s",
  },
  data() {
    return {
      profile: {
        src: require("@/assets/profile.png"),
      },
      allList: [
        {
          heading: "EDUCATION",
          items: [
            {
              period: "Fall 2021 - Spring 2024(expected)",
              strong: "The Ohio State University",
              span: ", Columbus, OH",
              desc: [
                "M.F.A. degree in Theatre (Design emphasis)",
                "Primary emphasis: Media Design, secondary emphasis: Scenic Design",
              ],
            },
            {
              period: "Fall 2016 - Spring 2021",
              strong: "Oberlin College",
              span: ", Oberlin, OH",
              desc: [
                "B.A. degree in Studio Art",
                "Courses taken: Art and Technology, Color Theory, Drawing, Installation Art, Costume Design, Painting, Photography, Scenic Design",
              ],
            },
            {
              period: "Spring 2013 - Spring 2016",
              strong: "Korean Advanced Preparatory Academy",
              span: ", Yangpyeong County, South Korea",
            },
            {
              period: "Spring 2014 - Fall 2014",
              strong: "Otto Schott Gymnasium Mainz Gonsenheim",
              span: ", Mainz, Germany",
              dsec: [
                "Visiting student, took English, Art, Music, and Biology classes",
              ],
            },
          ],
        },
        {
          heading: "EXTENDED EDUCATION",
          items: [
            {
              period: "Summer 2019",
              strong: "Design Nas Academy",
              span: ", Seoul, South Korea",
              desc: ["Learned typography, layout, and design basics"],
            },
          ],
        },
        {
          heading: "EXPERIENCE",
          items: [
            {
              period: "Winter 2019 - Summer 2020",
              strong: "Design Intern at Naru",
              span: ", Chungju, South Korea",
              desc: [
                "Assisted in developing a 3D AR game about Goguryeo cultural heritage that the visitors can enjoy at Chungju Jungwon Cultural Foundation",
                "Researched the history and costume of Goguryeo and sketched the preliminary character design for the historical figures in 2D",
              ],
            },
            {
              period: "Summer 2019 - Fall 2019",
              strong: "Math and English Teacher at Oak Hill Academy",
              span: ", Seongnam, South Korea",
              desc: [
                "Taught elementary school and middle school students after school",
                "Rewarded for improving a student’s math score from C to A",
              ],
            },
            {
              period: "Fall 2019",
              strong: "Assistant Manager at Campus U",
              span:
                ", a coworking space for different companies, Seoungnam, South Korea",
              desc: [
                "Engaged in communicating with the customers, organizing mails, setting up appointments and contracts, and managing the lounge",
              ],
            },
            {
              period: "Spring 2019",
              strong: "Shadowed Professor Laura Carlson-Tarantowski",
              span: " at Oberlin College",
              desc: [
                "Weekly meeting with the professor to observe scenic design process and engage in scenic painting in the paint shop",
              ],
            },
            {
              period: "Summer 2018",
              strong: "Design Intern at Design Silo",
              span: ", Chungju, South Korea",
              desc: [
                "Assisted in designing a local memorial for Sangsul Yi, a Korean independence activist",
                "Researched and summarized Mr. Yi’s life into a graphic design on the wall",
              ],
            },
            {
              period: "Summer 2018",
              strong: "Local Election Campaign Assistant",
              span:
                " for 10 days during the election season in Jung District, Seoul, South Korea",
              desc: [
                "Assisted Hae Kyung Lee, a former member of the National Assembly, by summarizing the candidate’s achievements for the local community and waving placards",
              ],
            },
          ],
        },
        {
          heading: "QUALIFICATION & SKILLS",
          items: [
            {
              desc: [
                "Computer: Photoshop, Illustrator, Premiere, After Effects, Arduino, Processing, MadMapper",
                "Other: Certified Emission Trader at KRICCCS(Korea Research Institute of Climate Change Countermeasure Strategies)",
              ],
            },
          ],
        },
        {
          heading: "ACTIVITIES",
          items: [
            {
              period: "Spring 2017",
              strong: "Oberlin Student Ensemble",
              span:
                " conducted by Colin Roshak, Oberlin College and Conservatory: First Violin",
            },
            {
              period: "Summer 2015",
              strong: "Oriental Painting Group Exhibition",
              span: " at Insa Gallery, Seoul, South Korea",
              desc: ["Participated as a member of Duksan Studio"],
            },
            {
              period: "Summer 2015",
              strong: "Joo Young Oh in Korea",
              span: " , Seoul, South Korea",
              desc: ["Participated in Finale as one of 100 Young Artists"],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
